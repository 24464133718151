// Notifications.js
import React, { useEffect, useState } from 'react';
import './Notifications.css';


const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await fetch('https://api.fl-itservice.de/notifications.php'); // Stelle sicher, dass dieser Endpunkt existiert
                if (!response.ok) {
                    throw new Error('Fehler beim Abrufen der Meldungen.');
                }
                const data = await response.json();
                setNotifications(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchNotifications();
        const interval = setInterval(fetchNotifications, 10000); // Aktualisierung alle 10 Sekunden
        return () => clearInterval(interval);
    }, []);

    if (loading) {
        return <div>Lade...</div>;
    }

    if (error) {
        return <div>Fehler: {error}</div>;
    }

    return (
        <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Client</th>
                            <th>Level</th>
                            <th>Nachricht</th>
                            <th>Datum</th>
                            <th>Status</th>
                            <th>Aktionen</th>
                        </tr>
                    </thead>
                    <tbody>
                    {notifications.length === 0 ? (
                        <tr>
                            <td colSpan="12">Keine Meldungen vorhanden.</td>
                        </tr>
                    ) : (
                        notifications.map(notification => (
                            <tr key={notification.id} class="note-blink">
                                <td>{notification.id}</td>
                                <td>
                                    <a href={`/edit/${notification.client_name}`}>
                                    {notification.client_name}
                                    </a>
                                </td>
                                <td>
                                    {notification.warning_type === 'Warning' ? (
                                        <text className="note-warning">
                                            {notification.warning_type}
                                        </text>
                                    ) : notification.warning_type === 'Critical' ? (
                                        <text className="note-critical">
                                            {notification.warning_type}
                                        </text>
                                    ) : notification.warning_type === 'Info' ? (
                                        <text className="note-info">
                                            {notification.warning_type}
                                        </text>
                                    ) : (
                                        notification.warning_type
                                    )}
                                </td>
                                <td>{notification.message}</td>
                                <td>{new Date(notification.timestamp).toLocaleString('de-DE')}</td>
                                <td></td>
                                <td>
                                    <a href={`/note/${notification.id}`}>
                                        <button className="btn btn-sm btn-primary">Bearbeiten</button>
                                    </a>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
                </table>
            </div>
    );
};

export default Notifications;