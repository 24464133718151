// Header.js
import React from 'react';

const Sidebar = () => {

    return (
        <div>
        <div class="sidebar">
          <div class="sidebar-wrapper">
            <ul class="nav">
              <li class="">
                <a href="/">
                  <p>Meldungen</p>
                </a>
              </li>
              <li class="">
                <a href="/clients">
                  <p>Agents</p>
                </a>
              </li>
              <li class="">
                <a href="/cronjobs">
                  <p>CronJobs</p>
                </a>
              </li>
              <li class="">
                <a href="/">
                  <p>Downloads</p>
                </a>
              </li>
              <li class="">
                <a href="/">
                  <p>Einstellungen</p>
                </a>
              </li>
              <li class="">
                <a href="/users">
                  <p>Benutzer</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
        </div>
        
    );
};

export default Sidebar;