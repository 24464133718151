// Notifications.js
import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import LoadingScreen from './LoadingScreen';

const Users = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await fetch('https://api.fl-itservice.de/users.php'); // Stelle sicher, dass dieser Endpunkt existiert
                if (!response.ok) {
                    throw new Error('Fehler beim Abrufen der Benutzer.');
                }
                const data = await response.json();
                setUsers(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchNotifications();
    }, []);

    if (loading) {
        return <div><LoadingScreen /></div>;
    }

    if (error) {
        return <div>Fehler: {error}</div>;
    }

    return (
        <div>
        <div class="wrapper">
        {/* Sidebar */}
        <Sidebar />
        {/* Sidebar Ende */}
        <div class="main-panel">
          <Header />
          <div class="content">
          <div class="d-flex justify-content-betwenn">
                <div class="col-md-6">
                    <h2 class="card-title">Benutzer</h2>
                </div>
                <div class="col-md-6">
                    <button class="btn-sm btn-primary float-right">Benutzer anlegen</button>
                </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    
                  </div>
                  <div class="card-body">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Aktionen</th>
                                </tr>
                            </thead>
                            <tbody>
                            {users.length === 0 ? (
                                <tr>
                                    <td colSpan="12">Keine Benutzer vorhanden.</td>
                                </tr>
                            ) : (
                                users.map(user => (
                                    <tr key={user.id}>
                                        <td>{user.id}</td>
                                        <td>{user.firstname} {user.lastname}</td>
                                        <td>{user.username}</td>
                                        <td></td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                        </table>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
};

export default Users;
