import React from 'react';


const LoadingScreen = () => {

  return (
    <div style={styles.loadingContainer}>
        <h2>Daten werden geladen...</h2>
    </div>
  );
};

const styles = {
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        fontSize: '24px',
        color: '#333',
    },
};

export default LoadingScreen;
