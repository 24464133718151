import React, { useEffect, useState } from 'react';
import Header from './Header'; 
import Sidebar from './Sidebar';


const Cronjobs = () => {
  const [cronjobs, setCronjobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCronjobs = async () => {
      try {
        const response = await fetch('https://api.fl-itservice.de/jobs.php'); // Stelle sicher, dass dieser Endpunkt existiert
        if (!response.ok) {
          throw new Error('Fehler beim Abrufen der Meldungen: ' + response.statusText);
        }
        const data = await response.json();
        setCronjobs(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    fetchCronjobs();
    const interval = setInterval(fetchCronjobs, 10000); // Aktualisierung alle 10 Sekunden
    return () => clearInterval(interval);
  }, []);

  // Funktion zum Formatieren des Zeitstempels in deutscher Zeit (inkl. CET/CEST)
  const formatDateToGermanTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      timeZone: 'Europe/Berlin',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',  // Optional: Sekundengenauigkeit hinzufügen
    };
    return date.toLocaleString('de-DE', options);
  };

  if (error) {
    return <div>Fehler: {error}</div>;
  }

  return (
    <div>
      <div class="wrapper">
        {/* Sidebar */}
        <Sidebar />
        {/* Sidebar Ende */}
        <div class="main-panel">
          <Header />
        <div class="content">

          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                    <div class="row">
                      <div class="col-sm-3">
                        <h2 class="card-title">Aktive Cronjobs</h2>
                      </div>
                    </div>
                </div>
                <div class="card-body">
                  <table class="table tablesorter">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Cronjob</th>
                        <th>Beschreibung</th>
                        <th>Status</th>
                        <th>letzte Ausführung</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cronjobs.length === 0 ? (
                         <tr>
                            <td colSpan="12">Keine Jobs vorhanden.</td>
                          </tr>
                      ) : (
                        cronjobs.map(job => (
                          <tr key={job.id}>
                            <td>{job.id}</td>
                            <td>{job.name}</td>
                            <td>{job.description}</td>
                            <td className={job.status === 'success' ? 'color-online' : 'color-offline'}>
                              {job.status}
                            </td>
                            <td>{formatDateToGermanTime(job.timestamp)}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
                
              
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Cronjobs;
