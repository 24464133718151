
/* EDITCLIENT */
/* Hier werden alle Daten zu einem ausgewählten Client angezeigt bzw. bearbeitet */
/* Der Clientname wird mit der URL (GET) mitgeliefert */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header'; 
import Sidebar from './Sidebar';
import LoadingScreen from './LoadingScreen';
import $ from 'jquery'; // Import jQuery für Bootstrap Notify
import 'bootstrap-notify'; // Import Bootstrap Notify


const EditNote = () => {
    const { note_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [noteData, setNoteData] = useState([]);

    /* Hier wird beim Aufruf der Seite ausgeführt */
    useEffect(() => {
        /* Hier werden die Daten des ausgewählten Clients geladen */
        const fetchNoteData = async () => {
            const response = await fetch(`https://api.fl-itservice.de/note.php?id=${note_id}`);
            if (response.ok) {
                const data = await response.json();
                setNoteData(data);
            } else {
                console.error('Fehler beim Abrufen der Note-Daten:', response.statusText);
            }
            setLoading(false);
        };


        fetchNoteData();

    }, [note_id]);

    const handleDeleteNote = async () => {
        const response = await fetch(`https://api.fl-itservice.de/note.php?id=${note_id}`, {
            method: 'DELETE',
        });

        if (response.ok) {
            $.notify({
                icon: "tim-icons icon-check-2",
                message: "Meldung wurde erfolgreich gelöscht."
            }, {
                type: 'success',
                timer: 3000,
                placement: {
                    from: "top",
                    align: "center"
                }
            });
        } else {
            $.notify({
                icon: "tim-icons icon-alert-circle-exc",
                message: "Meldung konnte nicht gelöscht werden."
            }, {
                type: 'danger',
                timer: 3000,
                placement: {
                    from: "top",
                    align: "center"
                }
            });
        }
    };

    
    if (loading) {
        return <div><LoadingScreen /></div>;
    }

    if (!noteData) {
        return <div>Meldung nicht gefunden</div>;
    }

    const options = {
        responsive: true,
    };


    return (
        <div>
            <div class="wrapper">
            {/* Sidebar */}
            <Sidebar />
            {/* Sidebar Ende */}
            <div class="main-panel">
            <Header />
            <div class="content">
            <div class="d-flex justify-content-betwenn">
                <div class="col-md-6">
                    <h2 class="card-title">Meldung [{noteData.id}] von {noteData.client_name}</h2>
                </div>
                <div class="col-md-6">
                    <button onClick={handleDeleteNote} class="btn-sm btn-primary float-right">Meldung löschen</button>
                    <button class="btn-sm btn-primary float-right">Als Ticket übertragen</button>
                    <button class="btn-sm btn-primary float-right">Meldung quittieren</button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                Details
                            </div>
                            <div class="card-body">
                                <table class="table">
                                    <tbody>
                                    <tr>
                                        <td>Zeitstempel</td>
                                        <td>{noteData.timestamp}</td>
                                    </tr>
                                    <tr>
                                        <td>Type</td>
                                        <td>{noteData.warning_type}</td>
                                    </tr>
                                    <tr>
                                        <td>Parameter</td>
                                        <td>{noteData.parameter}</td>
                                    </tr>
                                    <tr>
                                        <td>Nachricht</td>
                                        <td>{noteData.message}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                </div>
            </div>
            </div>
            </div>
        </div>
    </div>    
    );
};

export default EditNote;